import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"

const Animation = ({
  threshold = 0.15,
  triggerOnce = false,
  right = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <div
      ref={ref}
      style={{
        // adjust these as desired
        transition: "opacity 600ms, transform 600ms",
        opacity: inView ? 1 : 0,
        display: "flex",
        transform: `translateX(${inView ? 0 : 100}px)`,
      }}
      {...remainingProps}
    />
  )
}

const Event = () => {
  const [banner, setBanner] = useState()
  const [name, setName] = useState()
  const [eventUrl, setEventUrl] = useState()
  const [eventDescription, setEventDescription] = useState()

  useEffect(() => {
    fetch("/hello")
      .then(res => res.json())
      .then(json => {
        console.log({ json })
        setEventDescription(json.description)
        setBanner(json.banner.publicUrl)
        setName(json.title)
        setEventUrl(json.url)
      })
      .catch(err => console.error(err))
  }, [])

  return (
    // <Animation>
    //   <div className="event-bg">
    //     <section className="section-event">
    //       <h2>Prochain événement</h2>
    //       <h3 className="post-card-title">{name}</h3>
    //       <p>{eventDescription}</p>
    //       <Link to={eventUrl} className="post-card-link">
    //         <img src={banner} alt={name} />
    //       </Link>
    //       <Link to={eventUrl} className="post-card-link event-button">
    //         Accéder à la billeterie
    //       </Link>
    //     </section>
    //   </div>
    // </Animation>

    <Animation>
      <div className="event-bg">
        <section className="section-event">
          <h2>Prochain événement</h2>
          <h3 className="post-card-title">Concert de Printemps</h3>
          <p>
            <b>Dimanche 11 mai à 17h au Cadran</b>
            <br />
            <br />
            Ouverture de la billeterie mi-avril.
            <br />
            Tarifs : adulte 10 euros / jeune 5 euros / gratuit pour
            les - de 12 ans
          </p>
          {/* <Link to={eventUrl} className="post-card-link"> */}
          <img src={banner} alt={name} />
          {/* </Link> */}
          {/* <Link to={eventUrl} className="post-card-link event-button">
            Accéder à la billeterie
          </Link> */}
        </section>
      </div>
    </Animation>
  )
}

export default Event
